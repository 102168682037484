







































































import { defineComponent, PropType, ref, watch, computed } from '@vue/composition-api'
import Modal from '@/components/Modal.vue'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import InputLabel from '@/elements/InputLabel.vue'
import { FileToSet } from '@/views/Programs/Participants/vues/import/xls_import'
import { ParticipantPartsReturn, usePlayerImport } from '@/composables/PlayerImport'
import { LeaguePlayer } from '@/GeneratedTypes/LeaguePlayer'
import { getEmptyLeague } from '@/lib/support/models/League/data'
import participantsClient from '@/clients/participantsClient'
import { updateTemplateWithEdits } from '@/views/Programs/Participants/ext/composeutils'
import { ImportProductMappingInfo } from '@/GeneratedTypes/ListInfo/ImportProductMappingInfo'
import { PlayerProgram } from '@/GeneratedTypes/PlayerProgram'
import { ValidationError } from 'yup'
import ImportErrorsTable, { ImportErrorsType } from '@/views/Programs/Participants/vues/ImportErrorsTable.vue'
import ProgressIndicator from '@/views/Programs/Participants/vues/ProgressIndicator.vue'
import { cloneDeep } from 'lodash'
import {
  getEmptyLeagueProgram,
  ProgramHasGradeAndGender,
  leagueProductImportMapping,
} from '@/lib/support/models/LeagueProgram/data'
import { LeagueAbstraction } from '@/models/LeagueAbstraction/LeagueAbstraction'
import { DuplicateOptions, duplicateOptionList } from '@/views/Programs/Participants/ext/import'
import { PlayerProduct } from '@/GeneratedTypes/PlayerProduct.ts'
import { getGradeTypeIDFromBirthDate } from '@/services/gradeService'
import store from '@/store'
import dayjs from 'dayjs'

export default defineComponent({
  name: 'ParticipantImport',

  components: { ProgressIndicator, ImportErrorsTable, Modal, RadioGroupInput, InputLabel },
  props: {
    visible: { type: Boolean, required: true, default: false },
    participants: { type: Array as PropType<LeaguePlayer[]>, required: true, default: () => [] },
    league: { type: Object as PropType<LeagueAbstraction>, required: true, default: () => getEmptyLeague() },
  },
  setup(props, ctx) {
    const internalVisible = ref(false)

    watch(
      () => props.visible,
      () => (internalVisible.value = props.visible),
      { immediate: true }
    )
    function close(b: boolean) {
      cancelImport.value = true
      if (!b) {
        if (saved.value > 0) {
          ctx.emit('dirtyClose')
        } else {
          ctx.emit('cleanClose')
        }
      }
    }
    const duplicateChoices = ref(duplicateOptionList)
    const duplicateChoice = ref(duplicateOptionList[0].value)
    const step = ref(1)
    const errors = ref<string[]>([])
    const import_errors = ref<ImportErrorsType[]>([])
    const shouldShowErrors = ref(true)
    const imported = ref(0)
    const total = ref(0)
    const text = ref('')
    const saved = ref(0)
    const cancelImport = ref(false)

    const isByAge = computed(() => props.league.ageCutoffDate != null)

    function showErrors() {
      shouldShowErrors.value = !shouldShowErrors.value
    }

    /**
     * Load a player from the DB
     * @param duplicateChoice
     * @param player
     */
    async function getPlayerFromParticipantList(
      duplicate: number,
      player: LeaguePlayer,
      line: number
    ): Promise<LeaguePlayer | null> {
      let mergePlayer: LeaguePlayer | null = null

      const existingID = props.participants.find((x) => {
        return x.firstName == player.firstName && x.lastName == player.lastName
      })?.individualID

      if (existingID && duplicate == DuplicateOptions.SKIP) {
        import_errors.value.push({
          line: line,
          error: `Duplicate: skipping ${player.firstName} ${player.lastName}`,
        })
        return null
      }

      if (existingID && duplicate == DuplicateOptions.UPDATE) {
        mergePlayer = await participantsClient.retrieveTemplate(
          props.league?.upwardLeagueID ?? '',
          existingID
        )
        return cloneDeep(mergePlayer)
      }

      //new participant
      mergePlayer = await participantsClient.retrieveTemplate(props.league?.upwardLeagueID ?? '')
      return cloneDeep(mergePlayer)
    }

    function getProgramFromPlayer(getCheer: boolean, mergePlayer: LeaguePlayer): PlayerProgram | null {
      let program: PlayerProgram | null = null
      if (getCheer) {
        program = mergePlayer.programs?.find((x) => x.typeProgramID.match(/.*CHEER.*/)) ?? null
        if (!program) {
          return null
        }
      } else {
        program = mergePlayer.programs?.length ? mergePlayer.programs[0] : null
      }
      return cloneDeep(program)
    }

    function buildParticipant(
      program: PlayerProgram,
      YearsExperience: number,
      parts: ParticipantPartsReturn,
      mergePlayer: LeaguePlayer,
      mappings: ImportProductMappingInfo[]
    ) {
      program.yearsExperience = YearsExperience
      const udfs =
        props.league?.playerUDFs?.map((x, i) => ({
          ...(parts.udfs.find((x) => x.udfid == i) ?? { udfid: 0, udfValue: '' }),
          udfid: x.udfid,
        })) ?? []

      const result = updateTemplateWithEdits(
        mergePlayer,
        {
          udfs: udfs!.filter((x) => x.udfValue),
          practiceNightExclusions: parts.practiceNightExclusions,
          player: parts.player,
          contacts: parts.contacts,
        },

        {
          carpoolLink: parts.carpoolLink,
          coach: parts.coach,
          evaluations: [],
          payments: parts.payments,
          program: program,
          products: program.products || ([] as PlayerProduct[]),
        }
      )

      // set product sizes
      const programs = result.programs
      const importableProducts = [] as PlayerProduct[]
      if (programs && programs.length) {
        programs.forEach((prog) => {
          if (!prog || !prog.products?.length) return
          prog.products.forEach((p) => {
            //Find the participant's product in the list of mappings (if it exists)
            const map = mappings.find((m) => {
              return m.gender == result.gender && m.productID == p.productID
            })
            if (map) {
              //Find the column name in the spreadsheet (if it exists)
              const xlProduct = parts.products.find((part) => {
                return part.columnName == map.importColumnName
              })
              if (xlProduct) {
                p.typeSizeID = xlProduct.value
                importableProducts.push(p)
              }
            }
          })
          prog.products = importableProducts
        })
      }

      result.isImported = true

      return cloneDeep(result)
    }

    async function uploadFiles(ev: InputEvent) {
      const t = ev.target as HTMLInputElement
      /**
       * get the mapping that connects the spreadsheet column name and the product IDs
       */
      const mappings = await leagueProductImportMapping(props.league.upwardLeagueID ?? '')
      const playerImport = usePlayerImport()
      await playerImport.buildSchema(mappings)

      if (t?.files?.length) {
        try {
          step.value = 2
          const sheet = await FileToSet(t.files[0])

          if (!sheet || sheet?.length <= 0) {
            text.value = 'Uploaded file not recognized'
          } else {
            try {
              await playerImport.validatePlayer(sheet[0], isByAge.value, true)
            } catch (e) {
              text.value = 'First row has import errors, check the file'
              if (e.errors?.length) {
                e.errors.map((x: string) => import_errors.value.push({ line: 0, error: x }))
                step.value = 3
              }
            }
          }

          let line = 0
          total.value = sheet.length
          /**
           * Step is being used for state 1=file selection; 2=importing; 3=cancelled/done state
           */
          for (let i = 0; i < sheet.length && step.value <= 2; i++) {
            if (cancelImport.value) break
            let r = sheet[i]
            try {
              line++
              imported.value = line

              const p = await playerImport.validatePlayer(r, isByAge.value)
              text.value = `Line ${line + 1}}`
              if (p) {
                text.value = `Line ${line + 1} ${p.FirstName} ${p.LastName}`
                const parts = playerImport.PlayerXLToParticipantParts(p, mappings.columnNames)
                if (isByAge.value) {
                  const typeID = getGradeTypeIDFromBirthDate(
                    props.league.ageCutoffDate,
                    parts.player.birthDate
                  )
                  if (typeID == null) {
                    import_errors.value.push({
                      line,
                      error: `This player is too old or too young based on the birthdate passed in: ${dayjs(
                        parts.player.birthDate ?? ''
                      ).format('MM/DD/YYYY')}`,
                    })
                    continue
                  } else {
                    parts.player.typeGradeID = typeID.upwardTypeID!
                  }
                }
                const mergePlayer = await getPlayerFromParticipantList(
                  duplicateChoice.value,
                  parts.player,
                  line
                )
                if (mergePlayer) {
                  const program = getProgramFromPlayer(parts.isCheer, mergePlayer)

                  if (program) {
                    if (
                      ProgramHasGradeAndGender(
                        props.league?.programs?.find((x) => x.typeProgramID == program.typeProgramID) ??
                          getEmptyLeagueProgram(),
                        parts.player.gender ?? 'E',
                        parts.player.typeGradeID
                      )
                    ) {
                      const result = buildParticipant(
                        program,
                        p.CheerYearsExperience || p.SportYearsExperience || 0,
                        parts,
                        mergePlayer,
                        mappings.leagueProductMappings
                      )
                      try {
                        await participantsClient.save(props.league?.upwardLeagueID ?? '', result, false, true)
                        saved.value++
                      } catch (e) {
                        if (e.errorObject?.errors?.length) {
                          e.errorObject.errors.forEach((x: { propertyName: string; message: string }) => {
                            import_errors.value.push({
                              line,
                              error: `Server: ${x.propertyName} ${x.message}`,
                            })
                          })
                        }
                        if (e.errorObject?.message) {
                          import_errors.value.push({ line, error: e.errorObject.message })
                        } else {
                          import_errors.value.push({ line, error: e.getMessage() })
                        }
                      }
                    } else {
                      import_errors.value.push({
                        line,
                        error: `The program specified does not have this ${
                          isByAge.value ? 'age' : 'grade'
                        } (${
                          isByAge.value
                            ? store.getters.gradeTypes.byUpwardTypeId(parts.player.typeGradeID)?.ageByCutoff
                            : parts.player.typeGradeID
                        }) or gender (${parts.player.gender}) combo`,
                      })
                    }
                  } else {
                    import_errors.value.push({
                      line,
                      error: parts.isCheer
                        ? 'Data Error with Server: Cheer program not found on model league player'
                        : 'Data Error with Server: No program found on template',
                    })
                  }
                }
              }
            } catch (e) {
              //likely validation error
              if (e instanceof ValidationError || e.errors) {
                import_errors.value.push({ line, error: e.message })
              }
            }
          }

          step.value = 2
        } catch (e) {
          step.value = 2
          errors.value = e.getMessage()
        } finally {
          step.value = 3
          text.value = 'Done.'
          ctx.emit('imported')
        }
      }
    }
    return {
      internalVisible,
      close,
      step,
      total,
      uploadFiles,
      import_errors,
      errors,
      saved,
      imported,
      showErrors,
      text,
      shouldShowErrors,
      duplicateChoices,
      duplicateChoice,
    }
  },
})
