import { ref } from '@vue/composition-api'
import * as yup from 'yup'
import { LeaguePlayer } from '@/GeneratedTypes/LeaguePlayer'
import { getEmptyLeaguePlayer } from '@/lib/support/models/LeaguePlayer/data'
import { PlayerContact } from '@/GeneratedTypes/PlayerContact'
import { getEmptyPlayerContact } from '@/lib/support/models/PlayerContact/data'
import {
  decrementingPhoneIDStrategy,
  getEmptyPhone,
  PhoneTypeEnum,
} from '@/lib/support/models/IndividualPhone/data'
import { getEmptyIndividualEmail } from '@/lib/support/models/IndividualEmail/data'
import { getEmptyIndividualAddress } from '@/lib/support/models/IndividualAddress/data'
import { PlayerPracticeNightExclusion } from '@/GeneratedTypes/PlayerPracticeNightExclusion'
import { PlayerUDFValue } from '@/GeneratedTypes/PlayerUDFValue'
import { PlayerPayment } from '@/GeneratedTypes/PlayerPayment'
import { CoachSelect } from '@/models/ParticipantUI/CoachSelect'
import { PlayerCarpoolLink } from '@/GeneratedTypes/PlayerCarpoolLink'
import {
  phoneEnum,
  stateAbbreviations,
  yesno,
  ziptest,
  ziptest_G3,
  ziptest_G2,
} from '@/lib/common/yup-validation/common-validations'
import { getGradesListFromAccount } from '@/services/gradeService'
import { UpwardPaymentMethodTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardPaymentMethodTypeID'
import { UpwardRelationshipTypeID } from '@/GeneratedTypes/UpwardTypes/UpwardRelationshipTypeID'
import { LeagueProductMapping } from '@/lib/support/models/LeagueProgram/data'
import ordersClient from '@/clients/ordersClient'
import store from '@/store'

export interface XLProduct {
  columnName: string
  value: string
}
export interface ParticipantPartsReturn {
  player: LeaguePlayer
  contacts: PlayerContact[]
  practiceNightExclusions: PlayerPracticeNightExclusion[]
  udfs: PlayerUDFValue[]
  payments: PlayerPayment[]
  coach: CoachSelect
  carpoolLink: PlayerCarpoolLink
  isCheer: boolean
  isPlayer: boolean
  yearsCheer: number
  yearsPlayer: number
  comments: string[]
  products: XLProduct[]
}

const playerImportShapeBase = {
  Player: yesno.default('YES'),
  Cheerleader: yesno.default('NO'),
  FirstName: yup.string().required().max(64),
  MI: yup.string().max(1).default(''),
  LastName: yup.string().max(64).required(),
  DOB: yup.date().required(),
  ChurchName: yup.string().max(64),
  SportYearsExperience: yup.number().max(12).min(0).default(0),
  CheerYearsExperience: yup.number().max(12).min(0).default(0),
  GeneralPlayerNotes: yup.string().max(256).default(''),
  Grade: yup.string(),
  Gender: yup
    .string()
    .required()
    .matches(/^[MF]$/, 'Gender must be M or F'),
  Height: yup.number().min(0).max(200),
  CarpoolLinkFirstName: yup.lazy((v) =>
    yup.string().when('CarpoolLinkLastName', {
      is: (lname) => (lname ? lname.length > 0 : false),
      then: yup
        .string()
        .max(32, `${v} is too long`)
        .required(`If CarpoolLinkLastName is supplied, CarpoolLinkFirstName is required`),
    })
  ),
  CarpoolLinkLastName: yup.lazy((v) =>
    yup.string().when('CarpoolLinkFirstName', {
      is: (fname) => (fname ? fname.length > 0 : false),
      then: yup
        .string()
        .max(32, `${v} is too long`)
        .required(`If CarpoolLinkFirstName is supplied, CarpoolLinkLastName is required`),
    })
  ),
  CoachLinkFirstName: yup.lazy((v) =>
    yup.string().when('CoachLinkLastName', {
      is: (lname) => (lname ? lname.length > 0 : false),
      then: yup
        .string()
        .max(32, `${v} is too long`)
        .required(`If CoachLinkLastName is supplied, CoachLinkFirstName is required`),
    })
  ),
  CoachLinkLastName: yup.lazy((v) =>
    yup.string().when('CoachLinkFirstName', {
      is: (fname) => (fname ? fname.length > 0 : false),
      then: yup
        .string()
        .max(32, `${v} is too long`)
        .required(`If CoachLinkFirstName is supplied, CoachLinkLastName is required`),
    })
  ),
  Amount: yup.number(),
  PaymentNote: yup.string().max(64),
  FirstName_EC: yup
    .string()
    .max(64)
    .required()
    .test(
      'no dups EC G2',
      'Contact name Error. [FirstName_EC LastName_EC] must be different than [FirstName_G2 LastName_G2]',
      function () {
        const ecName = `${(this.parent.FirstName_EC ?? '').trim()}${(
          this.parent.LastName_EC ?? ''
        ).trim()}`.toLowerCase()

        const g2Name = `${(this.parent.FirstName_G2 ?? '').trim()}${(
          this.parent.LastName_G2 ?? ''
        ).trim()}`.toLowerCase()

        if (!g2Name) return true
        return ecName != g2Name
      }
    )
    .test(
      'no dups EC G3',
      'Contact name Error. [FirstName_EC LastName_EC] must be different than [FirstName_G3 LastName_G3]',
      function () {
        const ecName = `${(this.parent.FirstName_EC ?? '').trim()}${(
          this.parent.LastName_EC ?? ''
        ).trim()}`.toLowerCase()

        const g3Name = `${(this.parent.FirstName_G3 ?? '').trim()}${(
          this.parent.LastName_G3 ?? ''
        ).trim()}`.toLowerCase()

        if (!g3Name) return true
        return ecName != g3Name
      }
    )
    .test(
      'no dups G2 G3',
      'Contact name Error. [FirstName_G2 LastName_G2] must be different than [FirstName_G3 LastName_G3]',
      function () {
        const g2Name = `${(this.parent.FirstName_G2 ?? '').trim()}${(
          this.parent.LastName_G2 ?? ''
        ).trim()}`.toLowerCase()

        const g3Name = `${(this.parent.FirstName_G3 ?? '').trim()}${(
          this.parent.LastName_G3 ?? ''
        ).trim()}`.toLowerCase()

        if (!g2Name || !g3Name) return true
        return g2Name != g3Name
      }
    ),
  LastName_EC: yup.string().max(64).required(),
  MobilePhone_EC: yup.string().max(48).required().matches(phoneEnum),
  WorkPhone_EC: yup.string().max(48).matches(phoneEnum),
  Email_EC: yup.string().email().required(),
  Address_EC: yup.string().required().max(128),
  City_EC: yup.string().required().max(64),
  State_EC: yup.string().oneOf(stateAbbreviations).required(),
  Zip_EC: ziptest.required(),
  FirstName_G2: yup.lazy((v) =>
    yup.string().when('LastName_G2', {
      is: (lname) => (lname ? lname.length > 0 : false),
      then: yup
        .string()
        .max(32, `${v} is too long`)
        .required(`If FirstName_G2 is supplied, LastName_G2 is required`),
    })
  ),
  LastName_G2: yup.lazy((v) =>
    yup.string().when('FirstName_G2', {
      is: (fname) => (fname ? fname.length > 0 : false),
      then: yup
        .string()
        .max(32, `${v} is too long`)
        .required(`If LastName_G2 is supplied, FirstName_G2 is required`),
    })
  ),
  MobilePhone_G2: yup.string().max(48).matches(phoneEnum),
  WorkPhone_G2: yup.string().max(48).matches(phoneEnum),
  Email_G2: yup.string().email(),
  Address_G2: yup
    .string()
    .max(128)
    .test('allRequired', 'If Address_G2 is provided, City_G2, State_G2, Zip_G2 is required', function () {
      if (!this.parent.Address_G2) return true
      return this.parent.City_G2 && this.parent.State_G2 && this.parent.Zip_G2
    }),
  City_G2: yup
    .string()
    .max(64)
    .test(
      'allRequired',
      'If City_G2 is provided, Address_G2, State_G2, and Zip_G2 are required',
      function () {
        if (!this.parent.City_G2) return true
        return this.parent.State_G2 && this.parent.Address_G2 && this.parent.Zip_G2
      }
    ),
  State_G2: yup
    .string()
    .oneOf(stateAbbreviations)
    .test(
      'allRequired',
      'If State_G2 is provided, Address_G2, City_G2, and Zip_G2 are required',
      function () {
        if (!this.parent.State_G2) return true
        return this.parent.City_G2 && this.parent.Address_G2 && this.parent.Zip_G2
      }
    ),
  Zip_G2: ziptest_G2,
  FirstName_G3: yup.lazy((v) =>
    yup.string().when('LastName_G3', {
      is: (fname) => (fname ? fname.length > 0 : false),
      then: yup
        .string()
        .max(32, `${v} is too long`)
        .required(`If FirstName_G3 is supplied, LastName_G3 is required`),
    })
  ),
  LastName_G3: yup.lazy((v) =>
    yup.string().when('FirstName_G3', {
      is: (lname) => (lname ? lname.length > 0 : false),
      then: yup
        .string()
        .max(32, `${v} is too long`)
        .required(`If LastName_G3 is supplied, FirstName_G3 is required`),
    })
  ),
  MobilePhone_G3: yup.string().max(48).matches(phoneEnum),
  WorkPhone_G3: yup.string().max(48).matches(phoneEnum),
  Email_G3: yup.string().email(),
  Address_G3: yup
    .string()
    .max(128)
    .test('allRequired', 'If Address_G3 is provided, City_G3, State_G3, Zip_G3 is required', function () {
      if (!this.parent.Address_G3) return true
      return this.parent.City_G3 && this.parent.State_G3 && this.parent.Zip_G3
    }),
  City_G3: yup
    .string()
    .max(64)
    .test(
      'allRequired',
      'If City_G3 is provided, Address_G3, State_G3, and Zip_G3 are required',
      function () {
        if (!this.parent.City_G3) return true
        return this.parent.State_G3 && this.parent.Address_G3 && this.parent.Zip_G3
      }
    ),
  State_G3: yup
    .string()
    .oneOf(stateAbbreviations)
    .test(
      'allRequired',
      'If State_G3 is provided, Address_G3, City_G3, and Zip_G3 are required',
      function () {
        if (!this.parent.State_G3) return true
        return this.parent.City_G3 && this.parent.Address_G3 && this.parent.Zip_G3
      }
    ),
  Zip_G3: ziptest_G3,
  UDF1: yup.string(),
  UDF2: yup.string(),
  UDF3: yup.string(),

  //these will get overriden with real values later.
  UniformSize: yup.string(), //.oneOf(sizes),
  TeeSize: yup.string(), //.oneOf(sizes),
  ShortsSize: yup.string(), //.oneOf(sizes),
  SockSize: yup.string(), //.oneOf(sockSizes),
  CheerTopSize: yup.string(), //.oneOf(cheerSizes),
  SkortsSize: yup.string(), //.oneOf(cheerSizes),
  CheerTurtleneckSize: yup.string(), //.oneOf(cheerSizes),
  PracticeDayExclusion: yup.string(), //.oneOf(daylist),
  PaymentType: yup.string().when('Amount', {
    is: (v: number | null) => v && v > 0,
    then: yup.string(), //.uppercase().oneOf(payments),
  }),
  Relationship_EC: yup.string(), //.uppercase().oneOf(relationships).required(),
  Relationship_G2: yup
    .string()
    .uppercase()
    //.oneOf(relationships)
    .test(
      'required',
      'FirstName_G2, LastName_G2, and  Relationship_G2 are required to create a guardian. One of them is missing',
      function () {
        if (this.parent.Relationship_G2) {
          return this.parent.FirstName_G2 && this.parent.FirstName_G2
        }
        if (this.parent.FirstName_G2 || this.parent.FirstName_G2) {
          return this.parent.Relationship_G2
        }
        return true
      }
    ),
  Relationship_G3: yup
    .string()
    .uppercase()
    //.oneOf(relationships)
    .test(
      'required',
      'FirstName_G3, LastName_G3, and  Relationship_G3 are required to create a guardian. One of them is missing',
      function () {
        if (this.parent.Relationship_G3) {
          return this.parent.FirstName_G3 && this.parent.FirstName_G3
        }
        if (this.parent.FirstName_G3 || this.parent.FirstName_G3) {
          return this.parent.Relationship_G3
        }
        return true
      }
    ),
}

const playerXLType = yup.object().shape(playerImportShapeBase).required()
export type PlayerXL = yup.InferType<typeof playerXLType>

export function usePlayerImport() {
  const myLeague = ref(store.getters.leagueAbstraction.currentItem)
  const schema = ref<any>(null)

  async function buildSchema(mappings: LeagueProductMapping) {
    const validGrades = () => {
      const retval: string[] = []
      if (myLeague.value.accounts != null) {
        myLeague.value.programs?.forEach((x) => {
          const gradeTypes = getGradesListFromAccount(myLeague.value.accounts![0], x.typeProgramID, 'E')
          const grades = gradeTypes.map((g) => g.upwardTypeID!)
          retval.push(...grades)
        })
      }

      return retval
    }

    const daylist = myLeague.value.practiceNights?.map((x) => x.typeDayID) ?? []
    const paymentTypes = store.getters.paymentTypes.allItems.map(
      (x: UpwardPaymentMethodTypeID) => x.upwardTypeID
    )
    const relationshipTypes = store.getters.relationshipTypes.allItems.map(
      (x: UpwardRelationshipTypeID) => x.upwardTypeID
    )

    const allProducts = (await ordersClient.getLeagueOrderItems(myLeague.value.upwardLeagueID ?? '')) ?? []

    function getSizes(colName: string, gender: string) {
      const retval: string[] = []
      const mapping = mappings.leagueProductMappings.find(
        (x) => x.importColumnName == colName && x.gender == gender
      )
      if (mapping) {
        const prod = allProducts.find((p) => p.id == mapping.productID)
        if (prod) {
          retval.push(...new Set(prod.productColorSizes?.map((x) => x.typeSizeID ?? '')))
        }
      }
      return retval
    }

    const uniformSizesMale = getSizes('UniformSize', 'M')
    const uniformSizesFemale = getSizes('UniformSize', 'F')

    const teeSizesMale = getSizes('TeeSize', 'M')
    const teeSizesFemale = getSizes('TeeSize', 'F')

    const shortsSizesMale = getSizes('ShortsSize', 'M')
    const shortsSizesFemale = getSizes('ShortsSize', 'F')

    const sockSizesMale = getSizes('SockSize', 'M')
    const sockSizesFemale = getSizes('SockSize', 'F')

    const cheerSizesMale = getSizes('CheerTopSize', 'M')
    const cheerSizesFemale = getSizes('CheerTopSize', 'F')

    const skortsSizesMale = getSizes('SkortsSize', 'M')
    const skortsSizesFemale = getSizes('SkortsSize', 'F')

    const turtleneckSizesMale = getSizes('CheerTurtleneckSize', 'M')
    const turtleneckSizesFemale = getSizes('CheerTurtleneckSize', 'F')

    const baseShape = {
      ...playerImportShapeBase,
      UniformSize: mappings.columnNames.includes('UniformSize')
        ? yup.string().when('Gender', {
            is: (g) => g == 'M',
            then: yup.string().oneOf(uniformSizesMale),
            otherwise: yup.string().oneOf(uniformSizesFemale),
          })
        : yup.string(),
      TeeSize: mappings.columnNames.includes('TeeSize')
        ? yup.string().when('Gender', {
            is: (g) => g == 'M',
            then: yup.string().oneOf(teeSizesMale),
            otherwise: yup.string().oneOf(teeSizesFemale),
          })
        : yup.string(),
      ShortsSize: mappings.columnNames.includes('ShortsSize')
        ? yup.string().when('Gender', {
            is: (g) => g == 'M',
            then: yup.string().oneOf(shortsSizesMale),
            otherwise: yup.string().oneOf(shortsSizesFemale),
          })
        : yup.string(),
      SockSize: mappings.columnNames.includes('SockSize')
        ? yup.string().when('Gender', {
            is: (g) => g == 'M',
            then: yup.string().oneOf(sockSizesMale),
            otherwise: yup.string().oneOf(sockSizesFemale),
          })
        : yup.string(),
      CheerTopSize: mappings.columnNames.includes('CheerTopSize')
        ? yup.string().when('Gender', {
            is: (g) => g == 'M',
            then: yup.string().oneOf(cheerSizesMale),
            otherwise: yup.string().oneOf(cheerSizesFemale),
          })
        : yup.string(),
      SkortsSize: mappings.columnNames.includes('SkortsSize')
        ? yup.string().when('Gender', {
            is: (g) => g == 'M',
            then: yup.string().oneOf(skortsSizesMale),
            otherwise: yup.string().oneOf(skortsSizesFemale),
          })
        : yup.string(),
      CheerTurtleneckSize: mappings.columnNames.includes('CheerTurtleneckSize')
        ? yup.string().when('Gender', {
            is: (g) => g == 'M',
            then: yup.string().oneOf(turtleneckSizesMale),
            otherwise: yup.string().oneOf(turtleneckSizesFemale),
          })
        : yup.string(),
      PracticeDayExclusion: yup.string().oneOf(daylist),
      PaymentType: yup.string().when('Amount', {
        is: (v: number | null) => v && v > 0,
        then: yup.string().uppercase().oneOf(paymentTypes),
      }),
      Relationship_EC: yup.string().uppercase().oneOf(relationshipTypes).required(),
      Relationship_G2: yup
        .string()
        .uppercase()
        .oneOf(relationshipTypes)
        .test(
          'required',
          'FirstName_G2, LastName_G2, and  Relationship_G2 are required to create a guardian. One of them is missing',
          function () {
            if (this.parent.Relationship_G2) {
              return this.parent.FirstName_G2 && this.parent.FirstName_G2
            }
            if (this.parent.FirstName_G2 || this.parent.FirstName_G2) {
              return this.parent.Relationship_G2
            }
            return true
          }
        ),
      Relationship_G3: yup
        .string()
        .uppercase()
        .oneOf(relationshipTypes)
        .test(
          'required',
          'FirstName_G3, LastName_G3, and  Relationship_G3 are required to create a guardian. One of them is missing',
          function () {
            if (this.parent.Relationship_G3) {
              return this.parent.FirstName_G3 && this.parent.FirstName_G3
            }
            if (this.parent.FirstName_G3 || this.parent.FirstName_G3) {
              return this.parent.Relationship_G3
            }
            return true
          }
        ),
    }

    const shape = store.getters.leagueAbstraction.isByAge
      ? baseShape
      : { ...baseShape, Grade: yup.string().required().oneOf(validGrades()) }

    schema.value = yup
      .object()
      .shape(shape, [['CarpoolLinkFirstName', 'CarpoolLinkLastName']])
      .required()
  }

  async function validatePlayer(
    row: Record<string, unknown>,
    isByAge: boolean,
    showAllErrors = false
  ): Promise<PlayerXL | undefined> {
    try {
      Object.keys(row).forEach((key) => {
        if (row[key] == null || row[key] == '') {
          delete row[key]
        }
      })
      if (await schema.value.validate(row, { abortEarly: !showAllErrors })) {
        return schema.value.cast(row)
      }
    } catch (e) {
      throw e
    }
  }

  function PlayerXLToParticipantParts(p: PlayerXL, productColumnNames: string[]): ParticipantPartsReturn {
    const player_part: LeaguePlayer = {
      ...getEmptyLeaguePlayer(),
      birthDate: p.DOB as Date,
      churchName: p.ChurchName ?? null,
      gender: standardizeGender(p.Gender),
      generalNotes: p.GeneralPlayerNotes ?? null,
      isImported: true,
      typeGradeID: p.Grade ?? '',
      lastName: p.LastName,
      firstName: p.FirstName,
      middleInitial: p.MI ?? null,
    }

    const contacts_part: PlayerContact[] = []
    contacts_part.push({
      ...getEmptyPlayerContact(),
      firstName: p.FirstName_EC,
      lastName: p.LastName_EC,
      addresses: [
        {
          ...getEmptyIndividualAddress(),
          subdivision1: p.City_EC,
          subdivision2: p.State_EC,
          street1: p.Address_EC,
          postalCode: p.Zip_EC ?? '',
        },
      ],
      phoneNumbers: [
        {
          ...getEmptyPhone(decrementingPhoneIDStrategy()),
          phoneNumber: p.MobilePhone_EC,
          typePhoneID: PhoneTypeEnum.MOBILE,
        },
        ...(p.WorkPhone_EC
          ? [
              {
                ...getEmptyPhone(decrementingPhoneIDStrategy()),
                phoneNumber: p.WorkPhone_EC,
                typePhoneID: PhoneTypeEnum.WORK,
              },
            ]
          : []),
      ],
      emailAddresses: [{ ...getEmptyIndividualEmail(), emailAddress: p.Email_EC }],
      isEmergencyContact: true,
      typeRelationshipID: p.Relationship_EC ?? '',
    })

    if (p.Relationship_G2) {
      contacts_part.push({
        ...getEmptyPlayerContact(),
        firstName: p.FirstName_G2 ?? '',
        lastName: p.LastName_G2 ?? '',
        addresses: [],
        phoneNumbers: [
          ...(p.MobilePhone_G2
            ? [
                {
                  ...getEmptyPhone(decrementingPhoneIDStrategy()),
                  phoneNumber: p.MobilePhone_G2,
                  typePhoneID: PhoneTypeEnum.MOBILE,
                },
              ]
            : []),
        ],
        emailAddresses: [],
        isEmergencyContact: false,
        typeRelationshipID: p.Relationship_G2,
      })
      const idx_G2 = contacts_part.findIndex(
        (i) => i.firstName == p.FirstName_G2 && i.lastName == p.LastName_G2
      )
      if (p.Email_G2) {
        contacts_part[idx_G2].emailAddresses = [{ ...getEmptyIndividualEmail(), emailAddress: p.Email_G2 }]
      }
      if (p.WorkPhone_G2) {
        contacts_part[idx_G2].phoneNumbers?.push({
          ...getEmptyPhone(decrementingPhoneIDStrategy()),
          phoneNumber: p.WorkPhone_G2,
          typePhoneID: PhoneTypeEnum.WORK,
        })
      }

      contacts_part[idx_G2].addresses!.push({
        ...getEmptyIndividualAddress(),
        subdivision1: p.City_G2 ?? '',
        subdivision2: p.State_G2 ?? '',
        street1: p.Address_G2 ?? '',
        postalCode: p.Zip_G2 ?? '',
      })
    }

    if (p.Relationship_G3) {
      contacts_part.push({
        ...getEmptyPlayerContact(),
        firstName: p.FirstName_G3 ?? '',
        lastName: p.LastName_G3 ?? '',
        addresses: [],
        phoneNumbers: [
          ...(p.MobilePhone_G3
            ? [
                {
                  ...getEmptyPhone(decrementingPhoneIDStrategy()),
                  phoneNumber: p.MobilePhone_G3,
                  typePhoneID: PhoneTypeEnum.MOBILE,
                },
              ]
            : []),
        ],
        emailAddresses: [],
        isEmergencyContact: false,
        typeRelationshipID: p.Relationship_G3,
        optOutEmails: false,
        optOutSMS: false,
      })
      const idx_G3 = contacts_part.findIndex(
        (i) => i.firstName == p.FirstName_G3 && i.lastName == p.LastName_G3
      )
      if (p.Email_G3) {
        contacts_part[idx_G3].emailAddresses = [{ ...getEmptyIndividualEmail(), emailAddress: p.Email_G3 }]
      }
      if (p.WorkPhone_G3) {
        contacts_part[idx_G3].phoneNumbers?.push({
          ...getEmptyPhone(decrementingPhoneIDStrategy()),
          phoneNumber: p.WorkPhone_G3,
          typePhoneID: PhoneTypeEnum.WORK,
        })
      }

      contacts_part[idx_G3].addresses!.push({
        ...getEmptyIndividualAddress(),
        subdivision1: p.City_G3 ?? '',
        subdivision2: p.State_G3 ?? '',
        street1: p.Address_G3 ?? '',
        postalCode: p.Zip_G3 ?? '',
      })
    }

    const pn_part: PlayerPracticeNightExclusion[] = []
    if (p.PracticeDayExclusion) {
      pn_part.push({
        typeDayID: p.PracticeDayExclusion ?? '',
      })
    }

    const udfs_part: PlayerUDFValue[] = []
    if (p.UDF1) {
      udfs_part.push({
        udfid: 1,
        udfValue: p.UDF1,
      })
    }
    if (p.UDF2) {
      udfs_part.push({
        udfid: 2,
        udfValue: p.UDF2,
      })
    }
    if (p.UDF3) {
      udfs_part.push({
        udfid: 3,
        udfValue: p.UDF3,
      })
    }

    const payments_part: PlayerPayment[] = []
    if (p.Amount) {
      payments_part.push({
        paymentID: -1,
        breakout: [
          {
            amount: p.Amount,
            breakoutID: 0,
            note: p.PaymentNote ?? '',
          },
        ],
        paymentDate: new Date(),
        paymentInfo: '',
        typePaymentMethodID: p.PaymentType ?? 'CASH',
      })
    }

    const coach_part: CoachSelect = {
      lastName: p.CoachLinkFirstName ? p.CoachLinkLastName ?? '' : '', //these require each other to be set or nothing.
      firstName: p.CoachLinkLastName ? p.CoachLinkFirstName ?? '' : '', //these require each other to be set or nothing
      pendingLinkID: 0,
      linkID: 0,
    }

    const carpool_part: PlayerCarpoolLink = {
      pendingFirstName: p.CarpoolLinkLastName ? p.CarpoolLinkFirstName ?? '' : '',
      pendingLastName: p.CarpoolLinkFirstName ? p.CarpoolLinkLastName ?? '' : '',
      pendingIndividualID: 0,
      player1IndividualID: 0,
      player2IndividualID: 0,
      id: 0,
    }

    const products = productColumnNames
      .map((c) => {
        //map column name to value in the spreadsheet
        const obj = {} as XLProduct
        obj.columnName = c
        //@ts-ignore
        obj.value = p[c]
        return obj
      })
      .filter((p) => p.value) //remove products not provided in spreadsheet

    function standardizeGender(gender: string): string {
      if (!gender) {
        return 'E'
      }
      return gender.substr(0, 1).toLocaleUpperCase() ?? 'E'
    }

    return {
      contacts: contacts_part,
      player: player_part,
      practiceNightExclusions: pn_part,
      udfs: udfs_part,
      carpoolLink: carpool_part,
      coach: coach_part,
      payments: payments_part,
      isCheer: (p.Cheerleader == 'YES' || p.Cheerleader == 'TRUE') ?? false,
      isPlayer: (p.Player == 'YES' || p.Player == 'TRUE') ?? false,
      yearsCheer: p.CheerYearsExperience ?? 0,
      yearsPlayer: p.SportYearsExperience ?? 0,
      comments: [],
      products: products,
    }
  }

  return {
    validatePlayer,
    PlayerXLToParticipantParts,
    buildSchema,
    //for testing purposes
    schema,
  }
}
