import { render, staticRenderFns } from "./ParticipantTransfer.vue?vue&type=template&id=3fa2f3d9&scoped=true&"
import script from "./ParticipantTransfer.vue?vue&type=script&lang=ts&"
export * from "./ParticipantTransfer.vue?vue&type=script&lang=ts&"
import style0 from "./ParticipantTransfer.vue?vue&type=style&index=0&id=3fa2f3d9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fa2f3d9",
  null
  
)

export default component.exports