import { DataTableHeader } from 'vuetify/types'

export const headers: DataTableHeader[] = [
  { value: 'prettyName', text: 'Name' },
  { value: 'firstAddress', text: 'Address' },
  { value: 'contacts', text: 'Parents' },
]

export const volunteerHeaders: DataTableHeader[] = [
  { value: 'prettyName', text: 'Name' },
  { value: 'gender', text: 'Gender' },
  { value: 'firstAddress', text: 'Address' },
]
