

































import { defineComponent, ref, watch } from '@vue/composition-api'

import { headers } from '@/components/ParticipantSearch/searchfiltercolumns'
import participantsClient from '@/clients/participantsClient'
import { PlayerSearchInfo } from '@/GeneratedTypes/ListInfo/PlayerSearchInfo'
import SearchModal from '@/elements/SearchModal.vue'

interface DisplayablePlayerSearchInfo extends PlayerSearchInfo {
  prettyName: string
}

export default defineComponent({
  name: 'ParticipantSearch',
  components: {
    SearchModal,
  },
  props: {
    autoSearch: { required: false, type: String, default: '' },
    league: { required: true, type: String, default: '' },
    visible: { required: false, type: Boolean, default: false },
  },
  setup(props, ctx) {
    const searchTimeout = ref<number | null>(null)
    const showModal = ref<boolean>(props.visible)
    const searchData = ref<DisplayablePlayerSearchInfo[]>([]) //define when activated
    const searchActive = ref(false) //not showing initially

    watch(
      () => props.visible,
      () => {
        showModal.value = props.visible
      }
    )

    watch(
      () => showModal.value,
      (b: boolean) => {
        if (b) {
          ctx.emit('open')
          return
        }

        ctx.emit('cancel')
        return
      }
    )

    watch(
      () => props.autoSearch,
      async () => {
        if (props.autoSearch) {
          const [first, last] = getFirstNameLastName(props.autoSearch)
          searchActive.value = true
          try {
            const result = [
              ...(await participantsClient.search({ leagueID: props.league, orSearch: false, first, last })),
            ]
            searchData.value = result.map((p) => {
              return {
                ...p,
                prettyName: `${p.firstName} ${p.lastName}`,
              } as DisplayablePlayerSearchInfo
            })
          } finally {
            searchActive.value = false
          }
          if (searchData.value.length) {
            showModal.value = true
          }
        }
      }
    )

    function searchSelected(p: PlayerSearchInfo) {
      ctx.emit('select', { id: p.individualID, leagueID: p.upwardLeagueID })
    }

    function getFirstNameLastName(s: string) {
      let searchSegments = s.split(',')
      let lastFirstSearch = true
      if (!searchSegments || searchSegments.length <= 1) {
        searchSegments = s.split(' ')
        lastFirstSearch = false
      }
      let endSegment = ''
      if (searchSegments.length > 1) {
        endSegment = searchSegments.slice(1).join(' ')
      }
      if (lastFirstSearch) {
        return [endSegment.trim(), searchSegments[0].trim()]
      }
      return [searchSegments[0].trim(), endSegment.trim()]
    }

    /***
     * Callback on the SearchModal
     * @param s
     */
    function updateSearch(s: string) {
      //4656 - make them type at least 3 characters before searching
      if (s.trim().length > 2) {
        if (searchTimeout.value) {
          clearTimeout(searchTimeout.value)
        }

        searchTimeout.value = window.setTimeout(async () => {
          await doSearch(s)
        }, 1000)
      }
    }

    async function doSearch(s: string) {
      const [first, last] = getFirstNameLastName(s)

      if (!searchActive.value) {
        searchActive.value = true
        try {
          const result = await participantsClient.search({
            leagueID: props.league,
            orSearch: true,
            first: first,
            last: last,
          })

          searchData.value = result.map((p) => {
            return {
              ...p,
              prettyName: `${p.firstName} ${p.lastName}`,
            } as DisplayablePlayerSearchInfo
          })
        } finally {
          searchActive.value = false
          searchTimeout.value = null
        }
      }
    }

    return {
      headers,
      showModal,
      searchActive,
      searchData,
      updateSearch,
      searchSelected,
    }
  },
})
