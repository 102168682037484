


































import { defineComponent, computed, ref, watch } from '@vue/composition-api'

import { headers } from '@/components/ParticipantSearch/searchfiltercolumns'
import participantsClient from '@/clients/participantsClient'
import { PlayerSearchInfo } from '@/GeneratedTypes/ListInfo/PlayerSearchInfo'
import SearchModal from '@/elements/SearchModal.vue'
import TextInput from '@/elements/TextInput.vue'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'

enum SearchChoice {
  EMAIL,
  PHONE,
  GUARDIAN,
}

interface PlayerSearchInfoDisplay extends PlayerSearchInfo {
  prettyName: string
}

export default defineComponent({
  name: 'ParticipantEmailPhoneSearch',
  components: {
    SearchModal,
    TextInput,
    RadioGroupInput,
  },
  props: {
    autoSearch: { required: false, type: String, default: '' },
    league: { required: true, type: String, default: '' },
    visible: { required: false, type: Boolean, default: false },
  },
  setup(props, ctx) {
    const key = ref(0)
    const showModal = ref<boolean>(props.visible)
    const searchData = ref<PlayerSearchInfoDisplay[]>([])
    const searchActive = ref(false)

    const choice = ref(SearchChoice.EMAIL)
    const term = ref('')

    watch(
      () => props.visible,
      () => {
        showModal.value = props.visible
      }
    )

    watch(
      () => showModal.value,
      (b: boolean) => {
        if (b) {
          ctx.emit('open')
          return
        }

        ctx.emit('close')
        return
      }
    )

    function cancel() {
      ctx.emit('cancel')
    }

    async function search() {
      searchActive.value = true
      const criteria = {
        leagueID: props.league,
        orSearch: false,
        historyLimit: null,
        includePastLeagues: false,
        ...getCriteria(),
      }
      try {
        const result = [...(await participantsClient.search(criteria))]
        searchData.value = result.map((p) => {
          return {
            ...p,
            prettyName: `${p.firstName} ${p.lastName}`,
          }
        })
      } finally {
        searchActive.value = false
      }
      if (searchData.value.length) {
        showModal.value = true
      }
    }

    function getCriteria() {
      switch (choice.value) {
        case SearchChoice.PHONE:
          return { phone: term.value }
        case SearchChoice.GUARDIAN:
          return { guardianFullName: term.value }
        case SearchChoice.EMAIL:
        default:
          return { email: term.value }
      }
    }

    function searchSelected(p: PlayerSearchInfoDisplay) {
      ctx.emit('select', { id: p.individualID, leagueID: p.upwardLeagueID })
    }

    const choiceName = computed(() => {
      switch (choice.value) {
        case SearchChoice.PHONE:
          return 'Phone Numbers'
        case SearchChoice.GUARDIAN:
          return 'Guardians'
        case SearchChoice.EMAIL:
        default:
          return 'Emails'
      }
    })

    function clear() {
      term.value = ''
    }

    return {
      headers,
      showModal,
      searchActive,
      searchData,
      searchSelected,
      cancel,
      key,
      choice,
      term,
      clear,
      search,
      choiceName,
    }
  },
})
