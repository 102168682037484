import { DataTableHeader } from 'vuetify/types'

export const headers: DataTableHeader[] = [
  {
    value: 'last',
    text: 'Last',
    width: 100,
  },
  {
    value: 'first',
    text: 'First',

    width: 100,
  },
  {
    value: 'gender',
    text: 'Gender',
    width: 60,
  },
  {
    value: 'grade',
    text: 'Grade',
    width: 60,
  },
  {
    value: 'UDF1',
    text: 'UDF1',
    width: 60,
  },
  {
    value: 'UDF2',
    text: 'UDF2',
    width: 60,
  },
  {
    value: 'UDF3',
    text: 'UDF3',
    width: 60,
  },
]

export const headersByAge: DataTableHeader[] = [
  {
    value: 'last',
    text: 'Last',
    width: 100,
  },
  {
    value: 'first',
    text: 'First',

    width: 100,
  },
  {
    value: 'gender',
    text: 'Gender',
    width: 60,
  },
  {
    value: 'ageByCutoff',
    text: 'Age',
    width: 60,
  },
  {
    value: 'UDF1',
    text: 'UDF1',
    width: 60,
  },
  {
    value: 'UDF2',
    text: 'UDF2',
    width: 60,
  },
  {
    value: 'UDF3',
    text: 'UDF3',
    width: 60,
  },
]
