






























































































import { computed, defineComponent, PropType, ref, watch, onMounted } from '@vue/composition-api'

import { headers, headersByAge } from './ext/transfer_gridcolumns'

import { LeaguePlayerInfo } from '@/GeneratedTypes/ListInfo/LeaguePlayerInfo'
import { PlayerUDFValueInfo } from 'src/GeneratedTypes/ListInfo/PlayerUDFValueInfo'
import { ParticipantTransfer } from '@/views/Programs/Participants/models/ParticipantTransfer'
import { DataTableSelection } from '@/models/DataTable/DataTableSelection'

import Modal from '@/components/Modal.vue'
import SelectInput from '@/elements/SelectInput.vue'
import InputLabel from '@/elements/InputLabel.vue'
import TextInput from '@/elements/TextInput.vue'
import GradeRangeDropdowns from '@/elements/GradeRangeDropdowns.vue'
import Loading from '@/elements/Loading.vue'
import GenderSelect from '@/elements/GenderSelect.vue'
import DataTable from '@/elements/DataTable/DataTable.vue'

import { filterGrades } from '@/services/gradeService.ts'
import participantsClient from '@/clients/participantsClient'
import { getGrades } from '@/lib/support/models/UpwardTypes/UpwardGradeTypeID/grades'

import store from '@/store'

export interface ChurchListType {
  id: string
  name: string
}
export default defineComponent({
  name: 'ParticipantTransfer',
  components: {
    SelectInput,
    Modal,
    DataTable,
    GradeRangeDropdowns,
    GenderSelect,
    InputLabel,
    TextInput,
    Loading,
  },
  props: {
    visible: { type: Boolean, required: true, default: false },
    churches: { type: Array as PropType<ChurchListType[]>, default: () => [], required: true },
    fromAccount: { type: String, required: true, default: '' },
    status: { type: String, required: false, default: '' },
    league: { type: String, required: false, default: '' },
  },
  data() {
    return { headers, headersByAge }
  },
  setup(props, ctx) {
    const internalVisible = ref(false)
    const ids = ref<number[]>([])
    const gender = ref<string>('E')
    const name = ref('')
    const program = ref('BOTH')
    const udfFilter1 = ref('')
    const udfFilter2 = ref('')
    const udfFilter3 = ref('')
    const minGrade = ref<string>('K3')
    const maxGrade = ref<string>('12th')
    const UDFLabel1 = ref<string>('')
    const UDFLabel2 = ref<string>('')
    const UDFLabel3 = ref<string>('')
    let localStatus = ref<string>('')
    let loading = ref(true)
    const participants = ref<LeaguePlayerInfo[]>([])
    const isByAge = store.getters.leagueAbstraction.isByAge

    const programs = computed((): { label: string; id: string }[] => {
      const list = [...store.getters.leagueAbstraction.shortPrograms]
      list.push({ label: 'Both', id: 'BOTH' })
      return list
    })

    const getParticipants = async () => {
      loading.value = true
      participants.value = (await participantsClient.retrieveExt(props.league)) ?? []
      loading.value = false
    }

    onMounted(async () => {
      await getParticipants()
    })

    watch(
      () => props.visible,
      () => (internalVisible.value = props.visible),
      { immediate: true }
    )

    watch(
      () => participants.value,
      () => {
        if (participants.value.length) {
          setUDFLabels()
        }
      },
      { immediate: true }
    )

    watch(
      () => props.status,
      async () => {
        if (props.status == 'done' && localStatus.value != '') {
          setTimeout(() => (localStatus.value = ''), 2500)
        }
        localStatus.value = props.status == 'done' ? 'Done' : props.status

        if (localStatus.value == 'Done') {
          await getParticipants()
        }
      }
    )

    function setUDFLabels() {
      UDFLabel1.value = udfLabel(0)
      UDFLabel2.value = udfLabel(1)
      UDFLabel3.value = udfLabel(2)
    }

    const showUDF = computed(() => {
      const labels = `${UDFLabel1.value}${UDFLabel2.value}${UDFLabel3.value}`
      return labels != '' && labels != 'N/AN/AN/A'
    })

    function udfLabel(udfIdx: number) {
      const firstUdfs = participants.value[0].udFs
      if (firstUdfs && firstUdfs[udfIdx] && firstUdfs[udfIdx].label) {
        return firstUdfs[udfIdx].label ?? 'No Label'
      }
      return 'N/A'
    }

    const data = computed(() => {
      return participants.value
        .filter((x) => {
          const act = x.accountNumber == props.fromAccount
          const gr = filterGrades(minGrade.value, maxGrade.value, x.typeGradeID)
          const gd = gender.value == 'E' ? true : gender.value == x.gender
          const nm = stringContainsFilter(`${x.firstName}${x.lastName}`, name.value)
          const udf1 = udfContainsFilter(x.udFs, 0, udfFilter1.value)
          const udf2 = udfContainsFilter(x.udFs, 1, udfFilter2.value)
          const udf3 = udfContainsFilter(x.udFs, 2, udfFilter3.value)
          const pg = program.value == 'BOTH' ? true : program.value == x.typeProgramID

          return act && gr && gd && nm && udf1 && udf2 && udf3 && pg
        })
        .map((x) => ({
          id: x.individualID,
          last: x.lastName,
          first: x.firstName,
          gender: x.gender,
          grade: x.typeGradeID,
          ageByCutoff: store.getters.gradeTypes.byUpwardTypeId(x.typeGradeID!)?.ageByCutoff,
          UDF1: x.udFs && x.udFs.length > 0 ? x.udFs[0].udfValue ?? '' : '',
          UDF2: x.udFs && x.udFs.length > 1 ? x.udFs[1].udfValue ?? '' : '',
          UDF3: x.udFs && x.udFs.length > 2 ? x.udFs[2].udfValue ?? '' : '',
        }))
    })

    function stringContainsFilter(playerVal: string, filter: string) {
      return filter != '' ? playerVal.toLowerCase().indexOf(filter.toLowerCase()) > -1 : true
    }

    function udfContainsFilter(udfs: PlayerUDFValueInfo[] | null, idx: number, filter: string) {
      if (!udfs) return true
      const udf = udfs[idx]
      const val = udf ? udf.udfValue ?? '' : ''
      if (val == '') return true
      return filter != '' ? val.toLowerCase().trim().indexOf(filter.trim().toLowerCase()) > -1 : true
    }

    const transferAccount = ref('')
    watch(
      () => props.churches,
      () => {
        if (props.churches.length) {
          transferAccount.value = props.churches[0].id
        }
      },
      { immediate: true }
    )

    function rowselected(a: DataTableSelection<ParticipantTransfer>, allSelected: ParticipantTransfer[]) {
      if (Array.isArray(allSelected) && allSelected.length) {
        ids.value = allSelected.map((x) => x.id)
      }
    }

    function clear() {
      program.value = 'BOTH'
      gender.value = 'E'
      udfFilter1.value = ''
      udfFilter2.value = ''
      udfFilter3.value = ''
      name.value = ''
      setGrades(getGrades(false))
    }

    function close(b: boolean) {
      if (!b) {
        //closed
        ctx.emit('close')
      }
    }

    function transfer() {
      ctx.emit('transfer', ids.value, transferAccount.value)
    }

    function setGrades(grades: string[]) {
      minGrade.value = grades[0]
      maxGrade.value = grades[grades.length - 1]
    }

    return {
      rowselected,
      internalVisible,
      ids,
      close,
      transfer,
      data,
      transferAccount,
      gender,
      setGrades,
      localStatus,
      UDFLabel1,
      UDFLabel2,
      UDFLabel3,
      name,
      loading,
      udfFilter1,
      udfFilter2,
      udfFilter3,
      programs,
      program,
      hasCheer: computed(() => store.getters.leagueAbstraction.hasCheer),
      clear,
      minGrade,
      maxGrade,
      showUDF,
      isByAge,
    }
  },
})
