/***
 * When pieces of edit come back we want to slot the edited components
 * back into the template to send the server.
 * @param edit
 * @param contactName
 * @param contactPhone
 * @param editPlayer

 */
import { LeaguePlayer } from '@/GeneratedTypes/LeaguePlayer'
import { DecomposedPlayerArgs } from '@/views/Programs/Participants/ext/decomposeutils'
import { DecomposedProgramArgs } from '@/views/Programs/Participants/ext/programutils'
import { PlayerContact } from '@/GeneratedTypes/PlayerContact'

import { humanEmailToEmailID } from '@/lib/support/models/IndividualEmail/data'

import { getEmptyPlayerContact } from '@/lib/support/models/PlayerContact/data'

import { CoachSelect } from '@/models/ParticipantUI/CoachSelect'
import { WeedThePhoneList } from '@/lib/support/models/IndividualPhone/array_utils'
import { getFirstEmail } from '@/lib/support/models/IndividualEmail/email_list_operations'
import { cloneDeep } from 'lodash'

import { IndividualAddress } from '@/GeneratedTypes/IndividualAddress'
import { stringToAddressTypeID } from '@/lib/support/models/IndividualAddress/data'

export function updateTemplateWithEdits(
  edit: LeaguePlayer,
  { contacts, player, practiceNightExclusions, udfs }: DecomposedPlayerArgs,
  { program, evaluations, coach, carpoolLink, payments, products }: DecomposedProgramArgs
): LeaguePlayer {
  // try to preserve unedited contacts.

  const otherContacts = cloneDeep([...combineContacts(edit.contacts ?? [], contacts)])

  return cloneDeep({
    ...edit,
    programs: [
      {
        ...program,
        ...getCoachInfo(coach),
        carpoolLink: carpoolLink,
        payments: payments,
        evaluations: evaluations,
        products: products
          .filter((u) => u.typeSizeID != 'N/A')
          .map((x) => ({ ...x, typeColorID: x.typeColorID ? x.typeColorID : 'N/A' })),
      },
    ],
    birthDate: player.birthDate,
    practiceNightExclusions: practiceNightExclusions,
    udFs: udfs,
    individualID: edit.individualID,
    churchName: player.churchName,
    firstName: player.firstName ?? '',
    gender: player.gender,
    generalNotes: player.generalNotes,
    lastName: player.lastName ?? '',
    medicalNotes: player.medicalNotes,
    middleInitial: player.middleInitial,
    typeGradeID: player.typeGradeID ?? '',
    gradeIsOverridden: player.gradeIsOverridden,
    contacts: [...otherContacts],
  })
}

export function updateTemplateWithEditsNew(
  edit: LeaguePlayer,
  { contacts, player, practiceNightExclusions, udfs }: DecomposedPlayerArgs
): LeaguePlayer {
  // try to preserve unedited contacts.

  const otherContacts = cloneDeep([...combineContacts(edit.contacts ?? [], contacts)])

  return cloneDeep({
    ...edit,
    programs:
      player.programs?.map((x) => {
        return {
          ...x,
          products: x.products
            ? x.products
                .filter((u) => u.typeSizeID != 'N/A')
                .map((x) => ({ ...x, typeColorID: x.typeColorID ? x.typeColorID : 'N/A' }))
            : null,
        }
      }) ?? null,
    birthDate: player.birthDate,
    practiceNightExclusions: practiceNightExclusions,
    udFs: udfs,
    individualID: edit.individualID,
    churchName: player.churchName,
    firstName: player.firstName ?? '',
    gender: player.gender,
    generalNotes: player.generalNotes,
    lastName: player.lastName ?? '',
    medicalNotes: player.medicalNotes,
    middleInitial: player.middleInitial,
    typeGradeID: player.typeGradeID ?? '',
    gradeIsOverridden: player.gradeIsOverridden,
    contacts: [...otherContacts],
  })
}

function getCoachInfo(coach: CoachSelect) {
  if (coach.linkID == 0 && coach.firstName != '' && coach.lastName != '') {
    return {
      pendingCoachLinkFirstName: coach.firstName,
      pendingCoachLinkLastName: coach.lastName,
      pendingCoachLinkIndividualID: coach.pendingLinkID,
      coachLinkIndividualID: coach.linkID,
    }
  }
  return {
    pendingCoachLinkFirstName: '',
    pendingCoachLinkLastName: '',
    pendingCoachLinkIndividualID: 0,
    coachLinkIndividualID: coach.linkID,
  }
}

let CONTACT_PRIORITY = 0

/**
 * Takes what we are given or the template and combines
 * that information with the fields available for edit.
 * @param originalContacts -- what came in on the template
 * @param contacts - what we have in the edit
 */
function combineContacts(originalContacts: PlayerContact[], contacts: PlayerContact[]) {
  // Reset contact priority so priorities will always be 1, 2, 3, etc.
  CONTACT_PRIORITY = 0
  return contacts.map((x: PlayerContact) => {
    const matchContact = matchContactOrUseTemplate(originalContacts, x)
    return {
      individualID: x.individualID < 0 ? 0 : x.individualID,
      typeRelationshipID: x.typeRelationshipID,
      isEmergencyContact: x.isEmergencyContact,
      phoneNumbers: WeedThePhoneList(x?.phoneNumbers || []),
      lastName: x.lastName,
      firstName: x.firstName,
      addresses:
        x.addresses
          //eliminate half addresses on contact save.
          ?.filter((x) => x.street1 && x.subdivision1 && x.subdivision2 && x.postalCode)
          .map((x) => ({
            ...x,
            typeAddressID: x.typeAddressID ? x.typeAddressID : stringToAddressTypeID('home'),
            priority: getAddressPriority(),
          })) || [],
      emailAddresses: getFirstEmail(x).emailAddress
        ? [
            {
              ...getFirstEmail(matchContact),
              typeEmailID: humanEmailToEmailID('home'),
              emailAddress: getFirstEmail(x).emailAddress,
            },
          ]
        : [],
      middleInitial: matchContact.middleInitial ?? '',
      priority: getContactPriority(),
      thirdPartyClientIDs: matchContact.thirdPartyClientIDs || [],
      optOutEmails: x.optOutEmails,
      optOutSMS: x.optOutSMS,
      inviteAllowed: x.inviteAllowed,
    }
  })
}

function getContactPriority() {
  return ++CONTACT_PRIORITY
}

/***
 * If a contact exists in the template that was edited, then update it, otherwise grab
 * a fresh contact from the template, as this is an added contract.
 * @param contacts
 * @param contact
 * @return existing contact, template or a new contact from the generator
 */
function matchContactOrUseTemplate(contacts: PlayerContact[], contact: PlayerContact): PlayerContact {
  const idx = contacts?.findIndex((x) => contact.individualID == x.individualID)
  if (idx !== undefined && idx >= 0) {
    return { ...contacts![idx] }
  }
  if (contacts?.length) {
    return { ...contacts[0] } ?? getEmptyPlayerContact()
  }
  return getEmptyPlayerContact()
}

/***
 * If a contact exists in the template that was edited, then update it, otherwise grab
 * a fresh contact from the template, as this is an added contract.
 * @return new list of addresses
 * @param addresses
 * @param address
 */
export function replaceOrInsertAddress(
  addresses: IndividualAddress[],
  address: IndividualAddress
): IndividualAddress[] {
  const idx = addresses?.findIndex((x) => address.addressID == x.addressID)
  if (idx !== undefined && idx >= 0) {
    const c = cloneDeep(addresses)
    c[idx] = { ...address }

    return c
  }
  return [
    ...addresses,
    {
      ...address,
      priority: getAddressPriority(),
      addressID: address.addressID <= 0 ? 0 : address.addressID,
    },
  ]
}

let ADDRESS_PRIORITY = 99
function getAddressPriority() {
  return ++ADDRESS_PRIORITY
}
